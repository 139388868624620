import { obtenerParametrosOtorgante, sendFile } from '../services/api.js';

const bucket = process.env.REACT_APP_BUCKET;

export const enviarApikey = (apikey, uuidCliente, uuidTransaccion) =>{
    const headers ={
        'Content-Type': 'multipart/form-data',
        'transaccion': uuidTransaccion,
        'cliente': uuidCliente,
        'apikey': apikey
    };
    const params = {
        'idOtorgante': apikey
    };
    return obtenerParametrosOtorgante(headers, params);
}

export const enviarArchivo = (objeto, archivo, lado, tipoArchivo, folder) => {

    let data = new FormData();
    let ruta = `${objeto.uuidOtorgante}/${objeto.uuidUser}/${objeto.uuidTrx}/${folder}`;
    // let tipo = '';
    // let objetoEvento = {};

    data.append("bucket", bucket);
    data.append("folder", ruta);
    data.append("uuid", localStorage.getItem("uuidUser"));
    data.append("file", archivo, (new Date().getTime()) + tipoArchivo);

    sendFile(objeto, data).then(({ status, data }) => {
        if (status === 200) {
            /* objetoEvento.status = status;
            objetoEvento.payload = data;
            objetoEvento.side = lado;
            evento('Envío Archivo OBS', 'Success', objetoEvento, true, objeto); */
        }
    }).catch(error => {
        console.log(`🚀 ----------------------------🚀`)
        console.log(`🚀 ~ sendFile ~ error:`, error)
        console.log(`🚀 ----------------------------🚀`)
        /* if (error.response) {
            const { data, status } = error.response;
            objetoEvento.status = status;
            objetoEvento.data = data;
            tipo = 'Error';
        } else if (error.request) {
            objetoEvento.error = error.request;
            tipo = 'Error';
        } else {
            objetoEvento.error = error.message;
            tipo = 'Exception';
        }
        evento('Envío Archivo OBS', tipo, objetoEvento, false, objeto); */
    });
}

export const almacenarArchivo = (objeto, archivo, tipoArchivo, rutaArchivo, contenedor = bucket) => {

    let data = new FormData();
    let ruta = rutaArchivo ? rutaArchivo : `${objeto.uuidOtorgante}/${objeto.uuidUser}/${objeto.uuidTrx}/carpeta_archivo`;
    // let tipo = '';
    // let objetoEvento = {};

    data.append("bucket", contenedor);
    data.append("folder", ruta);
    data.append("uuid", localStorage.getItem("uuidUser"));
    data.append("file", archivo, (new Date().getTime()) + tipoArchivo);

    sendFile(objeto, data).then(({ status, data }) => {
        if (status === 200) {
            /* objetoEvento.status = status;
            objetoEvento.payload = data;
            evento('Envío Archivo OBS', 'Success', objetoEvento, true, objeto); */
        }
    }).catch(error => {
        console.log(`🚀 ----------------------------🚀`)
        console.log(`🚀 ~ sendFile ~ error:`, error)
        console.log(`🚀 ----------------------------🚀`)
        /* if (error.response) {
            const { data, status } = error.response;
            objetoEvento.status = status;
            objetoEvento.data = data;
            tipo = 'Error';
        } else if (error.request) {
            objetoEvento.error = error.request;
            tipo = 'Error';
        } else {
            objetoEvento.error = error.message;
            tipo = 'Exception';
        }
        evento('Envío Archivo OBS', tipo, objetoEvento, false, objeto); */
    });
}