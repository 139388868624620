import React from 'react';
import './App.css';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";

import TestComponenteCapturaId from './pages/test_componente_captura_id';
import Finalizado from './pages/finalizado';
import { createBrowserHistory } from "history";


const history = createBrowserHistory();


function App() {
  return (

    <Router history={history}>
      <Switch>
        <Route path="/finalizado">
          <Finalizado />
        </Route>
        <Route path="/:apikey" >
          <TestComponenteCapturaId/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
