import { dicNacionalidad } from "./dictionaries";

export const extractValueMultiLang = (object) => {
    return object.latin || object.arabic || object.cyrillic;
}

export const shortDate = {
    timeZone: 'Etc/GMT+6',
    month: '2-digit',
    year: 'numeric',
    day: '2-digit'
};


export const extractValueDate = (obj, formatDate) => {
    if (!obj.empty) {
        const fecha = new Date();
        fecha.setFullYear(obj.year, obj.month - 1, obj.day);
        return fecha.toLocaleDateString('es-MX', formatDate);
    } else {
        return "Fecha no disponible";
    }
}

export const extraerCalleYNumero = (texto) => {
    const lineas = texto.split('\n');
    let primeraLinea = lineas[0];
    if (lineas.length === 3) { primeraLinea = lineas[0]; }
    else { primeraLinea = "NA NA"; }
    const palabras = primeraLinea.split(' ');
    const numero = palabras.pop();
    const calle = palabras.join(' ');
    return { calle, numero };
}

export const extraerColoniaYCP = (texto) => {
    const lineas = texto.split('\n');
    let segundaLinea = lineas[1];
    if (lineas.length === 3) { segundaLinea = lineas[1]; }
    else { segundaLinea = lineas[0]; }
    const palabras = segundaLinea.split(' ');
    // const codigoPostal = fnValidaCodigoPostal(palabras.pop());
    const codigoPostal = palabras.pop();
    const colonia = palabras.join(' ');
    return { colonia, codigoPostal };
}

export const extraerEntidadYCiudad = (texto) => {
    const lineas = texto.split('\n');
    let terceraLinea = lineas[2];
    if (lineas.length === 3) { terceraLinea = lineas[2]; }
    else { terceraLinea = lineas[1]; }
    const [municipio, entidadFederativa] = terceraLinea.split(',').map(item => item.trim());
    return { municipio, entidadFederativa };
}

export const fnValidaNacionalidadDiccionario = (nacion) => {
    let existe = false;
    let resultna = '';
    for (const clave in dicNacionalidad) {
        if (dicNacionalidad.hasOwnProperty(clave)) {
            if (clave === nacion) {
                existe = true;
                resultna = (dicNacionalidad)[clave];
            }
        }
    }
    if (existe) {
        return resultna;
    } else {
        return nacion;
    }
}

export const imageDataToBase64 = (imageData) => {
    if (imageData) {
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.putImageData(imageData, 0, 0);
            return canvas.toDataURL('image/png');
        } else {
            throw new Error('Canvas context is not supported');
        }
    } else return
}

export const validarFMMPermanente = (objeto, dateOfExpiryPermanent) => {
    if (dateOfExpiryPermanent) return true;

    if (objeto.day === 0 || objeto.month === 0 || objeto.year === 0)
        return true;
    else
        return false
}

export const mostrarData = (data) => {
    const objetoData = {};
    for (const propiedad in data) {
        switch (propiedad) {
            case 'backAdditionalProcessingInfo':
            case 'backImageAnalysisResult':
            case 'classInfo':
            case 'frontAdditionalProcessingInfo':
            case 'backViz':
            case 'frontViz':
            case 'mrz':
            case 'barcode':
            case 'dataMatchResult':
            case 'driverLicenseDetailedInfo':
                objetoData[propiedad] = obtenerValoresObjeto(data[propiedad]);
                break;
            case 'backCameraFrame':
            case 'barcodeCameraFrame':
            case 'frontCameraFrame':
                objetoData[propiedad] = data[propiedad].frame ? 'Contiene información' : 'No contiene información';
                break;
            case 'dateOfBirth':
            case 'dateOfExpiry':
            case 'dateOfIssue':
                objetoData[propiedad] = extractValueDate(data[propiedad], shortDate)
                break;
            case 'faceImage':
            case 'fullDocumentBackImage':
            case 'fullDocumentFrontImage':
            case 'signatureImage':
                objetoData[propiedad] = data[propiedad].rawImage ? 'Contiene información' : 'No contiene información';
                break;
            default:
                if (typeof data[propiedad] === 'object') {
                    const valor = extractValueMultiLang(data[propiedad]);
                    objetoData[propiedad] = valor ? valor : 'No contiene información';
                } else
                    objetoData[propiedad] = (data[propiedad] !== null && data[propiedad] !== undefined && data[propiedad] !== '') ? data[propiedad].toString() : 'No contiene información';
                break;
        }
    }
    return Object.entries(objetoData).map(([key, value], index) => (<li key={index}><b>{key}</b> : {value}</li>))
}

const obtenerValoresObjeto = (objeto) => {
    const nuevoObjeto = {}
    for (const propiedad in objeto) {
        if (Array.isArray(objeto[propiedad])) {
            nuevoObjeto[propiedad] = (objeto[propiedad].length > 0) ? obtenerValoresArreglo(objeto[propiedad]) : 'No contiene información';
        } else if (propiedad === 'dateOfBirth' || propiedad === 'dateOfExpiry' || propiedad === 'dateOfIssue') {
            nuevoObjeto[propiedad] = extractValueDate(objeto[propiedad], shortDate);
        } else if (typeof objeto[propiedad] === 'object') {
            const valor = extractValueMultiLang(objeto[propiedad]);
            nuevoObjeto[propiedad] = valor ? valor : 'No contiene información';
        } else {
            nuevoObjeto[propiedad] = (objeto[propiedad] !== null && objeto[propiedad] !== undefined && objeto[propiedad] !== '') ? objeto[propiedad].toString() : 'No contiene información';
        }
    }
    return (<ul>{Object.entries(nuevoObjeto).map(([key, value], index) => (<li key={index}><b>{key}</b> : {value}</li>))}</ul>)
}

const obtenerValoresArreglo = (arreglo) => {
    const nuevoObjeto = {}
    arreglo.forEach((value, index) => {
        if (typeof value === 'object') {
            nuevoObjeto[index] = obtenerValoresObjeto(value);
        } else {
            nuevoObjeto[index] = value;
        }

    })
    return (<ul>{Object.entries(nuevoObjeto).map(([key, value], index) => (<li key={index}><b>{key}</b> : {value}</li>))}</ul>)
}