import React, { useState, useEffect } from 'react'
/* Librerías */
import { Link, useLocation } from "react-router-dom"
/* Componentes */
import Header from '../components/headerNA'
import MostrarJson from './mostrar_json'
/* Fuciones */
import { asignarEstilosParametros } from '../services/configStyle'
import {
    extractValueMultiLang, shortDate, extractValueDate, extraerCalleYNumero, extraerColoniaYCP,
    extraerEntidadYCiudad, fnValidaNacionalidadDiccionario, imageDataToBase64, validarFMMPermanente
} from '../utilities/funcitons'


const Finalizado = () => {

    /* Hooks */
    const location = useLocation();
    const [data, setData] = useState(null);
    const [facePhoto, setFacePhoto] = useState();
    const [idFrontPhoto, setIdFrontPhoto] = useState();
    const [idBackPhoto, setIdBackPhoto] = useState();
    const [idSignaturePhoto, setIdSignaturePhoto] = useState();
    const [apiKey, setApiKey] = useState('');
    const [noInfo, setNoInfo] = useState(false);
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [informacionFrente, setInformacionFrente] = useState([]);
    const [informacionReverso, setInformacionReverso] = useState([]);


    useEffect(() => {
        const apikeyOtorgante = location.state?.apikey;
        if (apikeyOtorgante) {
            setApiKey(apikeyOtorgante);
        }
        const dataOtorgante = location.state?.dataOtorgante;
        if (dataOtorgante) {
            setDataOtorgante(dataOtorgante);
        }
        const data = location.state?.data;
        if (data) {
            console.log(`🚀 ---------------------------🚀`)
            console.log(`🚀 ~ useEffect ~ data:`, data)
            console.log(`🚀 ---------------------------🚀`)
            asignarOCR(data)
        }
    }, []);

    useEffect(() => {
        if (dataOtorgante.length > 0) asignarEstilosParametros(dataOtorgante);
    }, [dataOtorgante]);

    useEffect(() => {
        if (data?.front && Object.entries(data?.front).length > 0) {
            mostrarInformacionIdentificacion('front');
        }
    }, [data?.front]);

    useEffect(() => {
        if (data?.back && Object.entries(data?.back).length > 0) {
            mostrarInformacionIdentificacion('back');
        }
    }, [data?.back]);

    const asignarOCR = (dataResult) => {
        const numeroIdentificacion = dataResult.classInfo.documentType;
        if (numeroIdentificacion === 0) {
            setNoInfo(true)
        } else if (numeroIdentificacion === 25) {
            setFacePhoto(imageDataToBase64(dataResult.faceImage.rawImage))
            setIdFrontPhoto(imageDataToBase64(dataResult.fullDocumentFrontImage.rawImage))
            setData(data => ({
                ...data, 'front': {
                    'Nombre completo': `${extractValueMultiLang(dataResult.firstName)} ${extractValueMultiLang(dataResult.lastName)}`,
                    'Nombre(s)': extractValueMultiLang(dataResult.firstName),
                    'Apellidos': extractValueMultiLang(dataResult.lastName),
                    'Fecha de nacimiento': extractValueDate(dataResult.dateOfBirth, shortDate),
                    'CURP': extractValueMultiLang(dataResult.personalIdNumber),
                    'Sexo': (extractValueMultiLang(dataResult.sex) === 'M') ? 'Hombre' : 'Mujer',
                    'Lugar de nacimiento': extractValueMultiLang(dataResult.placeOfBirth),
                    'Numero de pasaporte': extractValueMultiLang(dataResult.documentNumber),
                    'Autoridad emisora': extractValueMultiLang(dataResult.issuingAuthority),
                    'Nacionalidad': fnValidaNacionalidadDiccionario(extractValueMultiLang(dataResult.nationality)),
                    'Vigencia': extractValueDate(dataResult.dateOfExpiry, shortDate),
                }
            }))
        } else if (numeroIdentificacion === 18) {
            setFacePhoto(imageDataToBase64(dataResult.faceImage.rawImage))
            setIdFrontPhoto(imageDataToBase64(dataResult.fullDocumentFrontImage.rawImage))
            setIdBackPhoto(imageDataToBase64(dataResult.fullDocumentBackImage.rawImage))
            setIdSignaturePhoto(imageDataToBase64(dataResult.signatureImage.rawImage))
            const { calle, numero } = extraerCalleYNumero(extractValueMultiLang(dataResult.address))
            const { colonia, codigoPostal } = extraerColoniaYCP(extractValueMultiLang(dataResult.address))
            const { entidadFederativa, municipio } = extraerEntidadYCiudad(extractValueMultiLang(dataResult.address))
            setData(data => ({
                ...data, 'front': {
                    'Nombre completo': `${extractValueMultiLang(dataResult.fullName)} ${extractValueMultiLang(dataResult.fathersName)} ${extractValueMultiLang(dataResult.mothersName)}`,
                    'Nombre(s)': extractValueMultiLang(dataResult.fullName),
                    'Apellido paterno': extractValueMultiLang(dataResult.fathersName),
                    'Apellido materno': extractValueMultiLang(dataResult.mothersName),
                    'Fecha de nacimiento': extractValueDate(dataResult.dateOfBirth, shortDate),
                    'CURP': extractValueMultiLang(dataResult.personalIdNumber),
                    'Sexo': (extractValueMultiLang(dataResult.sex) === 'H') ? 'Hombre' : 'Mujer',
                    'Calle': calle,
                    'Número calle': numero,
                    'Colonia': colonia,
                    'Código postal': codigoPostal,
                    'Municipio': municipio,
                    'Entidad federativa': entidadFederativa,
                    'Clave registro': extractValueMultiLang(dataResult.documentAdditionalNumber),
                    'Año de registro': extractValueMultiLang(dataResult.documentOptionalAdditionalNumber).substr(0, 4),
                    'Número de registro': extractValueMultiLang(dataResult.documentOptionalAdditionalNumber).substr(4, 6),
                    'Vigencia': dataResult.dateOfExpiry.year.toString(),
                }, 'back': {
                    'CIC': dataResult.mrz.documentNumber,
                    'Nombre completo': `${dataResult.mrz.secondaryID} ${dataResult.mrz.primaryID}`,
                    'Nombre(s)': dataResult.mrz.secondaryID,
                    'Apellido paterno': dataResult.mrz.primaryID.split(" ")[0],
                    'Apellido materno': dataResult.mrz.primaryID.split(" ")[1],
                    'Fecha de nacimiento': extractValueDate(dataResult.mrz.dateOfBirth, shortDate),
                    'Sexo': (dataResult.mrz.gender === 'H') ? 'Hombre' : 'Mujer',
                    'Indentificador del ciudadano': dataResult.mrz.sanitizedOpt1.substr(4),
                }
            }))
        } else if (numeroIdentificacion === 15 || numeroIdentificacion === 6) {
            setFacePhoto(imageDataToBase64(dataResult.faceImage.rawImage))
            setIdFrontPhoto(imageDataToBase64(dataResult.fullDocumentFrontImage.rawImage))
            setIdBackPhoto(imageDataToBase64(dataResult.fullDocumentBackImage.rawImage))
            setData(data => ({
                ...data, 'front': {
                    'Nombre completo': extractValueMultiLang(dataResult.fullName),
                    'Fecha de nacimiento': extractValueDate(dataResult.dateOfBirth, shortDate),
                    'CURP': extractValueMultiLang(dataResult.personalIdNumber),
                    'Sexo': extractValueMultiLang(dataResult.sex),
                    'Nacionalidad': extractValueMultiLang(dataResult.nationality),
                    'Vigencia': (validarFMMPermanente(dataResult.dateOfExpiry, dataResult.dateOfExpiryPermanent) ? 'PERMANENTE' : extractValueDate(dataResult.dateOfExpiry, shortDate)),
                }, 'back': {

                    'Número de documento': extractValueMultiLang(dataResult.documentAdditionalNumber),
                    'Número de FMM': extractValueMultiLang(dataResult.documentNumber),
                    'Autoridad emisora': extractValueMultiLang(dataResult.issuingAuthority),
                    'Lugar de nacimiento': fnValidaNacionalidadDiccionario(extractValueMultiLang(dataResult.placeOfBirth)),
                }
            }))
        } else {
            return
        }

    }

    const mostrarInformacionIdentificacion = (lado) => {
        let informacion = null;
        if (lado === 'front') {
            informacion = Object.entries(data.front).map(([key, value], index) => {
                return <p key={index}><b>{key}</b>: {value}</p>
            });
            setInformacionFrente(informacion);
        } else {
            informacion = Object.entries(data.back).map(([key, value], index) => {
                return <p key={index}><b>{key}</b>: {value}</p>
            });
            setInformacionReverso(informacion);
        }

    }

    return (
        <>
            <div className="main_gradient">
                <Header dataOtorgante={dataOtorgante} />
                {noInfo ? <>
                    <h1>Sin información para mostrar</h1>
                    <p>No se obtuvo el tipo de identificación.</p>
                </>
                    :
                    <div className="main_text_container">
                        {(data) &&
                            <>
                                {(data.front) && <>
                                    <h1>Información frontal</h1>
                                    {informacionFrente}
                                </>
                                }
                                {facePhoto && <>
                                    <p><b>Foto de rostro</b>:</p>
                                    <img alt='Foto de rostro' src={facePhoto} />
                                </>
                                }
                                {idFrontPhoto && <>
                                    <p><b>Anverso</b>:</p>
                                    <img alt='Anverso' src={idFrontPhoto} />
                                </>
                                }
                                {idSignaturePhoto && <>
                                    <p><b>Firma identificación</b>:</p>
                                    <img alt='Firma identificación' src={idSignaturePhoto} />
                                </>
                                }
                                {(data.back) && <>
                                    <hr />
                                    <h1>Información reverso</h1>
                                    {informacionReverso}
                                </>
                                }
                                {idBackPhoto && <>
                                    <p><b>Reverso</b>:</p>
                                    <img alt='Reverso' src={idBackPhoto} />
                                </>
                                }
                            </>

                        }
                    </div>
                }

                <MostrarJson data={location.state?.data} />
                <div className="action_buttons animated fadeIn delay-1s">
                    <Link to={{
                        pathname: `/${apiKey}`
                    }} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                        FINALIZAR</Link>

                </div>
            </div>
        </>
    );
}

export default Finalizado;
