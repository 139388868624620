import React from 'react';

const Index = () => {
    return (
        <>
            <div className="containerLoader">
                <span className="loader">
                    <div className="circleLoader"></div>
                </span>
            </div>
        </>
    );
}

export default Index;
