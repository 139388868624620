import React from "react";
/* Funciones */
import { mostrarData } from "../utilities/funcitons";

const MostrarJson = ({ data }) => {

    const showModal = () => {
        var modal = document.getElementById("myModal");
        var btn = document.getElementById("myBtn");
        var span = document.getElementsByClassName("close")[0];
        var span2 = document.getElementById("closeModal");

        btn.onclick = function () {
            modal.style.display = "block";
        }

        span.onclick = function () {
            modal.style.display = "none";
        }

        span2.onclick = function () {
            modal.style.display = "none";
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        }
    }


    return (
        <>
            <div className="ocr_data_display">
                <button type="button" id="myBtn"
                    className="btn btn-primary forcewidth100 main_color"
                    data-toggle="modal"
                    data-target="#modal_ine" onMouseOver={showModal}>
                    MOSTRAR DETALLE JSON
                </button>
            </div>

            <div className="modal" tabIndex="-1" role="dialog" id="myModal" /*id="modal_ine"*/>
                <div className="modal-dialog" role="document" >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Detalle</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            {(data) &&
                                <>
                                    <h5>OCR </h5>
                                    <ul>{mostrarData(data)}</ul>
                                </>
                            }
                        </div>

                        <div className="modal-footer" id="closeModal">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{ willChange: 'unset' }}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MostrarJson;
