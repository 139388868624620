import React, { Fragment, useState, useEffect } from 'react'
/* Funciones */
import { obtenerValorParametro } from '../services/configStyle'

const HeaderNA = ({ dataOtorgante: dataOtorganteProps }) => {
    const [dataOtorgante, setDataOtorgante] = useState([]);

    useEffect(() => {
        if (dataOtorganteProps) {
            setDataOtorgante(dataOtorganteProps)
        }
    }, [dataOtorganteProps])

    /*  useEffect(() => {
         if(dataOtorgante.length>0)configStyle(dataOtorgante);
     }, [dataOtorgante]) */

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo" src={(obtenerValorParametro(dataOtorgante, 'LOGO_OTORGANTE', "") !== "") ? obtenerValorParametro(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
            </div>
        </Fragment>
    );
}

export default HeaderNA;