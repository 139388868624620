import axios from 'axios';
import { generateHeaders } from './configuration-api/configuration';


const host = process.env.REACT_APP_URL;
const servicio = process.env.REACT_APP_SERVICE;

const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    sendFile: `${host}/v1/sec_${servicio}file`,
    getCredentialsMicroblink:  `${host}/v1/sec_${servicio}service/microblink/getConfiguration`,
    parametrosOtorgante: `${host}/v1/sec_${servicio}params`,
}

/*
 * ==============================================
 * Obtener parámetros otorgante
 * ==============================================
 */

export const obtenerParametrosOtorgante = (headers, params) => {
    return axios({
        method: 'get',
        url: endpoints.parametrosOtorgante,
        headers: headers,
        params: params
    });
}


/*
 * ==============================================
 * Obtener credenciales Microblink
 * ==============================================
 */

export const obtenerCredencialesMicroblink = (apikey) => {
    return axios({
        method: 'GET',
        url: endpoints.getCredentialsMicroblink,
        params: {
            apikey: apikey
        }
    })
}


/* Enviar archivo */
export const sendFile = (objeto, data) => {
    let headers = generateHeaders(objeto, 'form-data');    
    return axios({
        method: 'post',
        url: endpoints.sendFile,
        headers: headers,
        data: data
    })
}

