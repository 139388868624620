export const asignarEstilosParametros = (data) => {
    document.documentElement.style.setProperty('--color-primary', obtenerValorParametro(data, 'COLOR_PRIMARIO'));
    document.documentElement.style.setProperty('--color-secondary', obtenerValorParametro(data, 'COLOR_SECUNDARIO'));
    document.documentElement.style.setProperty('--color-accent', obtenerValorParametro(data, 'COLOR_ACENTUACION'));
    document.documentElement.style.setProperty('--color-text', obtenerValorParametro(data, 'COLOR_DE_TEXTO'));
}

export const obtenerValorParametro = (lista, elemento, valorPredeterminado) => {
    const elementoEncontrado = lista.find((item) => {
        return item.idCampo === elemento;
    })

    if (elementoEncontrado) {
        return elementoEncontrado.valor;
    }

    return valorPredeterminado;
}