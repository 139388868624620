export const dicNacionalidad ={
    "MEX" : "MEXICO",
    "D"   : "ALEMANIA",
    "D<<" : "ALEMANIA",
    "ARG" : "ARGENTINA",
    "BEL" : "BELGICA",
    "BLZ" : "BELICE",
    "BRA" : "BRASIL",
    "CAN" : "CANADA",
    "CHL" : "CHILE",
    "CHN" : "CHINA",
    "COL" : "COLOMBIA",
    "CRI" : "COSTA RICA",
    "CUB" : "CUBA",
    "ECU" : "ECUADOR",
    "SLV" : "EL SALVADOR",
    "ESP" : "ESPAÑA",
    "PHL" : "FILIPINAS",
    "FRA" : "FRANCIA",
    "GHA" : "GHANA",
    "GTM" : "GUATEMALA",
    "HND" : "HONDURAS",
    "HUN" : "HUNGRIA",
    "IND" : "INDIA",
    "IRL" : "IRLANDA",
    "ISR" : "ISRAEL",
    "ITA" : "ITALIA",
    "JAM" : "JAMAICA",
    "LTU" : "LITUANIA",
    "NIC" : "NICARAGUA",
    "NLD" : "PAISES BAJOS",
    "PAN" : "PANAMA",
    "PRY" : "PARAGUAY",
    "PER" : "PERU",
    "POL" : "POLONIA",
    "CZE" : "REPUBLICA CHECA",
    "DOM" : "REPUBLICA DOMINICANA",
    "ROU" : "RUMANIA",
    "LKA" : "SRI LANKA",
    "SWE" : "SUECIA",
    "GBR" : "REINO UNIDO",
    "URY" : "URUGUAY",
    "USA" : "ESTADOS UNIDOS",
    "UZB" : "UZBEKISTAN",
    "VEN" : "VENEZUELA",
    "UKR" : "UCRANIA",
    "AUS" : "AUSTRALIA",
    "BGR" : "BULGARIA",
    "AUT" : "AUSTRIA",
    "BLR" : "BIELORRUSIA",
    "BOL" : "BOLIVIA",
    "CHE" : "SUIZA",
    "BHS" : "BAHAMAS",
    "EST" : "ESTONIA",
    "FIN" : "FINLANDIA",
    "HTI" : "HAITI",
    "HT" : "HAITI",
    "HRV" : "CROACIA",
    "JPN" : "JAPON",
    "KOR" : "COREA DEL SUR",
    "LUX" : "LUXEMBURGO",
    "PRT" : "PORTUGAL",
    "RUS" : "RUSIA",
    "SRB" : "SERBIA",
    "SVK" : "ESLOVAQUIA",
    "SVN" : "ESLOVENIA",
    "ARM" : "ARMENIA",
    "THA" : "TAILANDIA",
    "TUN" : "TUNEZ",
    "TUR" : "TURQUIA",
    "TWN" : "TAIWAN",
    "VNM" : "VIETNAM",
    "IDN" : "INDONESIA",
    "MAR" : "MARRUECOS",
    "KGZ" : "KIRGUISTAN",
    "JOR" : "JORDANIA",
    "LBN" : "LIBANO",
    "ARE" : "EMIRATOS ARABES UNIDOS",
    "AFG" : "AFGANISTAN",
    "ALA" : "ISLAS ALAND",
    "ALB" : "ALBANIA",
    "DZA" : "ARGELIA",
    "ASM" : "SAMOA AMERICANA",
    "AND" : "ANDORRA",
    "AGO" : "ANGOLA",
    "AIA" : "ANGUILA",
    "ATA" : "ANTARTIDA",
    "ATG" : "ANTIGUA Y BARBUDA ",
    "ABW" : "ARUBA",
    "AZE" : "ASERBAIYAN",
    "BHR" : "BAHREIN",
    "BGD" : "BANGLADESH",
    "BRB" : "BARBADOS",
    "BEN" : "BENIN",
    "BMU" : "ISLAS BERMUDAS",
    "BTN" : "BUTAN",
    "BES" : "BONAIRE",
    "BIH" : "BOSNIA Y HERZEGOVINA",
    "BWA" : "BOTSUANA",
    "BVT" : "ISLA BOUVET",
    "IOT" : "TERRITORIO BRITANICO DEL OCEANO INDICO",
    "BRN" : "BRUNEI DARUSSALAM",
    "BFA" : "BURKINA FASO",
    "BDI" : "BURUNDI",
    "CPV" : "CABO VERDE",
    "KHM" : "CAMBOYA",
    "CMR" : "CAMERUN",
    "CYM" : "ISLAS CAIMAN",
    "CAF" : "REPUBLICA CENTROAFRICANA",
    "TCD" : "CHAD",
    "CXR" : "ISLA DE NAVIDAD",
    "CCK" : "ISLAS COCOS",
    "COM" : "COMORAS",
    "COG" : "CONGO",
    "COD" : "REPUBLICA DEMOCRATICA DEL CONGO",
    "COK" : "ISLAS COOK",
    "CIV" : "COSTA DE MARFIL",
    "CUW" : "CURAZAO",
    "CYP" : "CHIPRE",
    "DNK" : "DINAMARCA",
    "DJI" : "DJIBOUTI",
    "DMA" : "DOMINICA",
    "EGY" : "EGIPTO",
    "GNQ" : "GUINEA ECUATORIAL",
    "ERI" : "ERITREA",
    "ETH" : "ETIOPIA",
    "FLK" : "ISLAS MALVINA",
    "FRO" : "ISLAS FEROE",
    "FJI" : "FIYI",
    "GUF" : "GUAYANA FRANCESA",
    "PYF" : "POLINESIA FRANCESA",
    "ATF" : "TIERRAS AUSTRALES Y ANTARTICOS FRANCESES",
    "GAB" : "GABON",
    "GMB" : "GAMBIA",
    "GEO" : "GEORGIA",
    "GIB" : "GIBRALTAR",
    "GRC" : "GRECIA",
    "GRL" : "GROENLANDIA",
    "GRD" : "GRANADA",
    "GLP" : "GUADALUPE",
    "GUM" : "GUAM",
    "GGY" : "BAILIA DE GUERNSEY",
    "GIN" : "GUINEA",
    "GNB" : "GUINEA-BISAU",
    "GUY" : "GUAYANA",
    "HMD" : "ISLAS HEARD Y MCDONALD",
    "VAT" : "CIUDAD DEL VATICANO",
    "HNK" : "HONG KONG",
    "ISL" : "ISLANDIA",
    "IRN" : "IRAN",
    "IRQ" : "IRAK",
    "IMN" : "ISLA DE MAN",
    "JEY" : "JERSEY",
    "KAZ" : "KAZAJISTAN",
    "KEN" : "KENIA",
    "KIR" : "KIRIBATI",
    "PRK" : "COREA DEL NORTE",
    "KWT" : "KUWAIT",
    "LAO" : "LAOS",
    "LVA" : "LETONIA",
    "LSO" : "LESOTO",
    "LBR" : "LIBERIA",
    "LBY" : "LIBIA",
    "LIE" : "LIECHTENSTEIN",
    "MAC" : "MACAO",
    "MKD" : "MACEDONIA",
    "MDG" : "MADAGASCAR",
    "MWI" : "MALAUI",
    "MYS" : "MALASIA",
    "MDV" : "MALDIVAS",
    "MLI" : "MALI",
    "MLT" : "MALTA",
    "MHL" : "ISLAS MARSHALL",
    "MTQ" : "MARTINICA",
    "MRT" : "MAURITANIA",
    "MUS" : "MAURICIO",
    "MYT" : "MAYOTTE",
    "FSM" : "ESTADOS FEDERADOS DE MICRONESIA",
    "MDA" : "MOLDAVIA",
    "MCO" : "MONACO",
    "MNG" : "MONGOLIA",
    "MNE" : "MONTENEGRO",
    "MSR" : "MONTSERRAT",
    "MOZ" : "MOZAMBIQUE",
    "MMR" : "MYANMAR",
    "NAM" : "NAMIBIA",
    "NPL" : "NEPAL",
    "ANT" : "ANTILLAS NEERLANDESAS",
    "NTZ" : "ZONA NEUTRAL",
    "NCL" : "NUEVA CALEDONIA",
    "NZL" : "NUEVA ZELANDA",
    "NER" : "NIGER",
    "NGA" : "NIGERIA",
    "NIU" : "NIUE",
    "NFK" : "ISLA NORFOLK",
    "MNP" : "ISLAS MARIANAS DEL NORTE",
    "NOR" : "NORUEGA",
    "OMN" : "OMAN",
    "PAK" : "PAKISTAN",
    "PLW" : "PALAU",
    "PSE" : "PALESTINA",
    " PNG" : "PAPUA NUEA GUINEA",
    "PCN" : "ISLAS PITCAIRN",
    "PRI" : "PUERTO RICO",
    "QAT" : "CATAR",
    "REU" : "ISLA REUNION",
    "RWA" : "RUANDA",
    "BLM" : "SAN BARTOLOME",
    "SHN" : "SANTA ELENA, ASCENSION Y TRISTAN DE ACUÑA",
    "KNA" : "SAN CRISTOBAL Y NIEVES",
    "LCA" : "SANTA LUCIA",
    "MAF" : "ISLA DE SAN MARTIN (FRANCIA)",
    "SPM" : "SAN PEDRO Y MIQUELON",
    "VCT" : "SAN VICENTE Y LAS GRANADINAS",
    "WSM" : "SAMOA",
    "SMR" : "SAN MARINO",
    "STP" : "SANTO TOME Y PRINCIPE",
    "SAU" : "ARABIA SAUDITA",
    "SEN" : "SENEGAL",
    "SYC" : "SEYCHELLES",
    "SLE" : "SIERRA LEONA",
    "SGP" : "SINGAPUR",
    "SXM" : "ISLA DE SAN MARTIN (HOLANDA)",
    "SLB" : "ISLAS SALOMON",
    "SOM" : "SOMALIA",
    "ZAF" : "SUDAFRICA",
    "SGS" : "ISLAS GEORGIA Y SANDWICH DEL SUR",
    "SSD" : "SUDAN DEL SUR",
    "SDN" : "SUDAN",
    "SUR" : "SURINAM",
    "SJM" : "SVALBARD Y JAN MAYEN",
    "SWZ" : "SWAZILANDIA",
    "SYR" : "SIRIA",
    "TJK" : "TAYIKISTAN",
    "TZA" : "TANZANIA",
    "TLS" : "TIMOR ORIENTAL",
    "TGO" : "TOGO",
    "TKL" : "TOKELAU",
    "TON" : "TONGA",
    "TTO" : "TRINIDAD Y TOBAGO",
    "TKM" : "TURKMENISTAN",
    "TCA" : "ISLAS TURCAS Y CAICOS",
    "TUV" : "TUVALU",
    "UGA" : "UGANDA",
    "UMI" : "ISLAS ULTRAMARINAS MENORES DE LOS ESTADOS UNIDOS",
    "VUT" : "VANUATU",
    "VGB" : "ISLAS VIRGENES BRITANICAS",
    "VIR" : "ISLAS VIRGENES DE ESTADOS UNIDOS",
    "WLF" : "WALLIS Y FUTUNA",
    "ESH" : "SAHARA OCCIDENTAL",
    "YEM" : "YEMEN",
    "ZMB" : "ZAMBIA",
    "ZWE" : "ZIMBABUE"
    }