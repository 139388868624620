import React, { useState, useEffect, useCallback } from 'react'
/* Librerías */
import ComponenteCapturaIdentificacion from 'dicio-npm-captura-id-microblink'
import { v4 as uuidv4 } from 'uuid'
import { useHistory, useParams, /* useLocation */ } from 'react-router-dom'
/* Componentes */
import CircleLoader from '../components/circle_loader'
/* Servicios */
import { obtenerCredencialesMicroblink } from '../services/api'
/* Funciones */
import { enviarApikey } from '../services/data'
import { asignarEstilosParametros, obtenerValorParametro } from '../services/configStyle'

const TestComponenteCapturaId = () => {

    const { apikey } = useParams()
    const history = useHistory()
    /* Hooks */
    const [uuidTransaccion, setUuidTransaccion] = useState('')
    const [uuidCliente, setUuidCliente] = useState('')
    const [resultLicenseKey, setResultLicenseKey] = useState('')
    const [logo, setLogo] = useState('')
    const [colorPrimary, setColorPrimary] = useState('')
    const [colorSecondary, setColorSecondary] = useState('')
    const [colorAccent, setColorAccent] = useState('')
    const [colorText, setColorText] = useState('')
    const [resultPath, setResultPath] = useState('')
    const [indications, setIndications] = useState('Coloca tu identificación de frente.')
    const [retryFunction, setRetryFunction] = useState(null)
    const [progressLoadSDK, setProgressLoadSdk] = useState(0)
    const [loadSDK, setLoadSDK] = useState(false)
    const [captureReverse, setCaptureReverse] = useState(false)
    const [showComponent, setShowComponent] = useState(false)
    const [parametrosOtorgante, setParametrosOtorgante] = useState([])
    /* const search = useLocation().search;
    bucket: new URLSearchParams(search).get('bucket') || '',
    ruta: new URLSearchParams(search).get('ruta') || '', */

    const obtenerCredenciales = useCallback(async () => {
        try {
            const response = await obtenerCredencialesMicroblink(apikey)
            console.log(`🚀 ---------------------------------------------🚀`)
            console.log(`🚀 ~ obtenerCredenciales ~ response:`, response)
            console.log(`🚀 ---------------------------------------------🚀`)
            const { status, data } = response
            if (status === 200) {
                if (data.LicenseKey !== '') {
                    setResultLicenseKey(data.LicenseKey)
                }
                setResultPath('')
            }
        } catch (error) {
            console.log(`🚀 ---------------------------------------🚀`)
            console.log(`🚀 ~ obtenerCredenciales ~ error:`, error)
            console.log(`🚀 ---------------------------------------🚀`)
            getErrorCredentials(error)
            setRetryFunction(() => obtenerCredenciales)
        }
    }, [])

    const obtenerParametrosOtorgante = useCallback(async () => {
        try {
            const response = await enviarApikey(apikey, uuidTransaccion, uuidCliente)
            console.log(`🚀 ----------------------------------------------------🚀`)
            console.log(`🚀 ~ obtenerParametrosOtorgante ~ response:`, response)
            console.log(`🚀 ----------------------------------------------------🚀`)
            console.log(`🚀 ---------------------------------------------🚀`)
            const { data: { payload } } = response
            setParametrosOtorgante(payload)
            setShowComponent(true)
        } catch (error) {
            console.log(`🚀 ----------------------------------------------🚀`)
            console.log(`🚀 ~ obtenerParametrosOtorgante ~ error:`, error)
            console.log(`🚀 ----------------------------------------------🚀`)
        }
    }, [])


    useEffect(() => {
        setUuidTransaccion(uuidv4())
        setUuidCliente(uuidv4())
        if (!window.location.hostname.includes('localhost')) {
            setResultPath('https://extraccionocr.devdicio.net')
            setResultLicenseKey('sRwCABpleHRyYWNjaW9ub2NyLmRldmRpY2lvLm5ldAZsZXlKRGNtVmhkR1ZrVDI0aU9qRTNNVGs0TlRJMU9USTBOVGdzSWtOeVpXRjBaV1JHYjNJaU9pSTJZV0k1TVdKaU15MHdabUprTFRRNVl6SXRPVEkwTVMxaFl6QXlORFZoT1dGbFl6Y2lmUT09VAHj2Dkm08zK5MyjL94tgSI8BsdXMaX+slpVLVYezha6Bm2erb4ovFC5JC9dPmoSI0EkQz7mkUYQ6SiwmTiZR3eLcqA8o4mdOC/4ELWvr0CRMQ8PbtztIERz/GYn1hZCgnqZSUJ+1sM7jC6qHLHNGofD')
        }
    }, [])

    useEffect(() => {
        if (apikey && uuidTransaccion && uuidCliente) obtenerParametrosOtorgante()
    }, [apikey, uuidTransaccion, uuidCliente])

    useEffect(() => {
        if (apikey && window.location.hostname.includes('localhost')) obtenerCredenciales()
    }, [obtenerCredenciales, apikey])

    useEffect(() => {
        if (parametrosOtorgante.length > 0) {
            asignarEstilosParametros(parametrosOtorgante)
            setLogo(obtenerValorParametro(parametrosOtorgante, 'LOGO_OTORGANTE', ''))
            setColorPrimary(obtenerValorParametro(parametrosOtorgante, 'COLOR_PRIMARIO', '#FCEDDB'))
            setColorSecondary(obtenerValorParametro(parametrosOtorgante, 'COLOR_SECUNDARIO', '#FCEDDB'))
            setColorAccent(obtenerValorParametro(parametrosOtorgante, 'COLOR_ACENTUACION', '#A1DB71'))
            setColorText(obtenerValorParametro(parametrosOtorgante, 'COLOR_DE_TEXTO', '#000000'))
        }
    }, [parametrosOtorgante])

    useEffect(() => {
        console.log(`🚀 ---------------------------------------🚀`)
        console.log(`🚀 ~ App ~ retryFunction:`, retryFunction)
        console.log(`🚀 ---------------------------------------🚀`)
    }, [retryFunction])

    useEffect(() => {
        console.log(`🚀 -------------------------------------------🚀`)
        console.log(`🚀 ~ App ~ progressLoadSDK:`, progressLoadSDK)
        console.log(`🚀 -------------------------------------------🚀`)
    }, [progressLoadSDK])

    useEffect(() => {
        console.log(`🚀 ---------------------------🚀`)
        console.log(`🚀 ~ App ~ loadSDK:`, loadSDK)
        console.log(`🚀 ---------------------------🚀`)
    }, [loadSDK])

    useEffect(() => {
        console.log(`🚀 -----------------------------------------🚀`)
        console.log(`🚀 ~ App ~ captureReverse:`, captureReverse)
        console.log(`🚀 -----------------------------------------🚀`)
        if (captureReverse === true) setIndications('Ahora, coloca el reverso de tu identificación.')
    }, [captureReverse])

    const getResult = (result) => {
        if (result) setShowComponent(false)
        setTimeout(() => {
            history.push({ pathname: '/finalizado', state: { apikey: apikey, dataOtorgante: parametrosOtorgante, data: result,  } })
        }, 300);
    }

    const getErrorCredentials = (error) => {
        console.log(`🚀 ---------------------------------------🚀`)
        console.log(`🚀 ~ getErrorCredentials ~ error:`, error)
        console.log(`🚀 ---------------------------------------🚀`)
    }

    const getErrorMediaStream = (error) => {
        console.log(`🚀 ---------------------------------------🚀`)
        console.log(`🚀 ~ getErrorMediaStream ~ error:`, error)
        console.log(`🚀 ---------------------------------------🚀`)
    }

    const getErrorSDK = (error) => {
        console.log(`🚀 -------------------------------🚀`)
        console.log(`🚀 ~ getErrorSDK ~ error:`, error)
        console.log(`🚀 -------------------------------🚀`)
    }

    const functionButtonCancel = (event) => {
        console.log(`🚀 ----------------------------------------🚀`)
        console.log(`🚀 ~ functionButtonCancel ~ event:`, event)
        console.log(`🚀 ----------------------------------------🚀`)
    }

    const props = {
        licenseKey: resultLicenseKey,
        path: resultPath,
        logo: logo,
        colorPrimary: colorPrimary,
        colorSecondary: colorSecondary,
        colorAccent: colorAccent,
        colorText: colorText,
        indications: (<><div className='div-indications'><h1>{indications}</h1><span className="evita_reflejos"> Evita reflejos de luz</span></div></>),
        functionButtonCancel,
        getResult,
        getErrorCredentials,
        getErrorMediaStream,
        getErrorSDK,
        setLoadSDK,
        setRetryFunction,
        setProgressLoadSdk,
        setCaptureReverse,
    }


    return (
        <>
            {showComponent ?
                <ComponenteCapturaIdentificacion {...props} />
                :
                <CircleLoader />
            }

        </>
    );
}

export default TestComponenteCapturaId;
